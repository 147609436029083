@import './_variables.scss';

* {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html {
  scroll-behavior: smooth
}

a {
  color: var(--white);
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

ul, li {
  list-style: none;
}

.app {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.main {
  display: flex;
  justify-content: center;
  flex: 1;
  margin-top: 80px;

  &--fixed {
    margin-top: 66px;
  }
}

.footer {
  flex-shrink: 0;
}

.text-underline {
  text-decoration: underline;
}

.container {
  &__desktop,
  &__desktop_outside {
    width: 100%;
    box-sizing: border-box;
  }
  &__desktop {
    max-width: 1120px;
  }
  &__desktop_outside {
    max-width: 1360px;
  }
}
.flex {
  &__col_center,
  &__row_center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__col_center {
    flex-direction: column;
  }
  &__row_center {
    flex-direction: row;
  }
}