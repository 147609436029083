.section {
  width: 100%;
  height: 100%;
  margin-top: -1px;
  background: #F5F5F6;

  .content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 120px;
    padding: 120px 0;
    border-radius: 60px 60px 0 0;
    background: #2A2C35;
  }

  .coin {
    position: absolute;
    top: -45px;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .title {
    margin-bottom: 48px;
    color: #FFFFFF;
    font-size: 56px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.56px;
    text-align: center;
  }

  .cards {
    display: flex;
    gap: 32px;
  }

  .card {
    width: 100%;
    max-width: 544px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    gap: 24px;

    padding: 32px;
    border-radius: 24px;
    background: #FFFFFF;

    .card_top {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }

    .card_bottom {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 20px;
    }

    .card_header {
      margin-bottom: 24px;

      img {
        width: 78px;
        height: auto;
      }

      p {
        margin-top: 15px;
        color: #000000CC;
        font-size: 32px;
        font-weight: 500;
        line-height: 38.4px;
        letter-spacing: 0.15px;
        text-align: center;
      }
    }

    .card_desc {
      max-width: 450px;
      display: flex;
      flex-direction: column;
      align-self: start;
      gap: 16px;
    }

    .card_text {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;

      img {
        width: 24px;
        height: 24px;
      }

      p {
        color: #000000CC;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-align: left;
      }
    }

    .card_price {
      color: #000000CC;
      font-size: 30px;
      font-weight: 700;
      line-height: 28px;
      letter-spacing: 0.89px;
      text-align: left;
    }

    &:last-child {
      box-shadow: 0 0 0 10px #FFDD2D inset;
    }
  }

  .btn_accept {
    max-width: 194px;
    height: 56px;
    padding: 16.4px 64.22px;
    margin-top: 24px;
  }
}

@media (max-width: 1180px) {
  .section {
    .content {
      padding-right: 10px;
      padding-left: 10px;
    }

    .cards {
      justify-content: center;
    }
  }
}

@media (max-width: 767.9px) {
  .section {
    .cards {
      flex-wrap: wrap;
    }
  }
}

@media (max-width: 600px) {
  .section {
    .content {
      padding-top: 64px;
      padding-bottom: 0;

      border-radius: 20px 20px 0 0;
    }

    .title {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.28px;
      text-align: center;
    }

    .cards {
      flex-wrap: wrap;

      .card_header {
        img {
          display: none;
        }
      }

      .card_bottom {
        width: 100%;

        .btn_accept {
          order: 1;
          margin-top: 0;
        }
      }

      .mobile_price {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 10px;

        .divider {
          width: 100%;
          border-bottom: 1px solid #00000066;
        }

        img {
          width: 28px;
        }

        p {
          min-width: 45px;
          padding-top: 3px;
        }

        .card_price_full {
          min-width: 85px;
        }
      }

      .card_mobile {
        box-shadow: none;
      }
    }

    .cards_mobile {
      width: calc(100% + 20px);
      padding-top: 24px;
      padding-bottom: 80px;
      margin-top: 32px;
      background: #FFDD2D;
    }
  }
}