.tabs {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    width: 130px;
    height: 44px;
    border: 1px solid #0010241F;
    background-color: transparent;
    cursor: pointer;
    transition: var(--transition);

    color: #000000;
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
    letter-spacing: 0.15px;
    text-align: center;

    &:first-child {
      border-radius: 12px 0 0 12px;
    }
    &:last-child {
      border-radius: 0 12px 12px 0;
    }
  }

  button.active {
    border: 2px solid #FFDD2D;
    transition: var(--transition);
  }
}

.tab_content {
  width: 100%;
  margin-top: 24px;
}
