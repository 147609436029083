.slide-from-top {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  transition: top 0.3s ease;
}

.slide-from-top.open {
  top: 0;

  .menu-content {
    padding: 70px 24px 35px 24px;
  }
}

.button {
  z-index: 9999;
  width: 30px;
  height: 30px;
  background: none;
  border: none;
  cursor: pointer;

  img {
    width: 100%;
    height: auto;
  }
}

.button_close {
  z-index: 9999;
  position: fixed;
  top: 25px;
  right: 30px;
  font-size: 30px;
  font-weight: bold;
  width: 30px;
  height: 30px;
  background: #11151D;
  border-radius: 50%;

  &:hover,
  &:focus {
    cursor: pointer;
  }
}

.menu-content {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: #11151D;

  .content_container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 48px !important;
    overflow: auto;

    .content_logo {
      width: 131px;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
    .content_talk {
      width: 312px;
      height: auto;

      img {
        width: 100%;
        height: auto;
      }
    }
    .content_links {
      display: flex;
      gap: 20px;

      a {
        color: #FFFFFF;
        font-size: 18px;
        font-weight: 500;
        line-height: 28.6px;
        letter-spacing: 0.15px;
        text-align: center;
      }
    }
    .desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;
      margin-bottom: 100px;

      .btn_accept {
        width: 312px;
        height: 51px;
        padding: 13.5px 87.86px 14.5px 87.84px;
        border-radius: 12px;
      }

      .btn_calendar {
        border: none;
        background: none;
        color: #428BF9;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.2px;
        text-align: left;
        text-decoration: none;
        cursor: pointer;
      }
    }
  }
}