.program {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 32px;

  .header {
    max-width: 688px;

    h3 {
      color: #000000CC;
      font-size: 32px;
      font-weight: 700;
      line-height: 38.4px;
      letter-spacing: 0.15px;
      text-align: center;
    }

    h4 {
      color: #0000008A;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
      text-align: center;
    }
  }

  .table {
    width: 100%;
    border-radius: 24px;
    border: 1px solid #D4D1D8;

    .content {
      width: 100%;
      display: flex;
      gap: 32px;

      &:first-child {
        .left_block {
          border-radius: 24px 0 0 0;
          border-bottom: 1px solid #F5F5F6;
        }

        .right_block {
          border-radius: 0 24px 0 0;
          border-bottom: 1px solid #D4D1D8;
        }
      }

      &:last-child {
        .left_block {
          border-radius: 0 0 0 24px;
        }
      }
    }

    .left_block {
      width: 100%;
      max-width: 352px;
      box-sizing: border-box;
      padding: 20px;
      border-radius: 24px 0 0 24px;
      background: #D4D1D8;

      p {
        margin-bottom: 24px;
        color: #000000CC;
        font-size: 28px;
        font-weight: 700;
        line-height: 33px;
        letter-spacing: 0.219px;
        text-align: left;
      }

      span {
        width: 93px;
        height: 32px;
        padding: 8px 10px;
        border-radius: 8px;
        background: #FFFFFF;

        color: #000000CC;
        font-size: 12.09px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0.15px;
        text-align: center;
      }
    }

    .right_block {
      width: 100%;
      max-width: 734px;
      padding: 20px 0 20px;
      border-radius: 0 24px 24px 0;

      .event {
        display: flex;
        justify-content: space-between;
        align-items: start;
        margin: 0 0 24px 0;
      }

      .event_desc {
        max-width: 446px;
        margin-right: 20px;
        color: #000000CC;
        font-size: 20px;
        font-weight: 400;
        line-height: 28px;
        letter-spacing: 0.219px;
        text-align: left;
      }

      .event_guid {
        width: 100%;
        max-width: 232px;
        padding-right: 20px;

        p {
          margin-bottom: 8px;
          color: #000000CC;
          font-size: 16px;
          font-weight: 500;
          line-height: 21.44px;
          letter-spacing: 0.159px;
          text-align: left;

          &:last-child {
            color: #8D8D9B;
            font-size: 16px;
            font-weight: 400;
            line-height: 21.44px;
            letter-spacing: 0.129px;
            text-align: left;
          }
        }
      }
    }

    .divider {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      border-bottom: 1px solid #D4D1D8;
    }
  }
}

@media (max-width: 1180px) {
  .program {
    .header {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}
@media (max-width: 992px) {
  .program {
    .table {
      .left_block {
        max-width: 250px;
      }
    }
  }
}

@media (max-width: 767.9px) {
  .program {
    .table {
      .left_block {
        max-width: 220px;
      }

      .right_block {
        .event_guid {
          max-width: 150px;
        }
      }
    }
  }
}
@media (max-width: 600px) {
  .program {
    .header {
      h3 {
        font-size: 22px;
        font-weight: 500;
        line-height: 28.6px;
        letter-spacing: 0.15px;
        text-align: center;
      }

      h4 {
        font-size: 17px;
        font-weight: 400;
        line-height: 23.12px;
        letter-spacing: 0.17px;
        text-align: center;
      }
    }

    .table {
      width: 100%;
      border-radius: 24px;
      border: 1px solid #D4D1D8;

      .content {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 16px;

        &:first-child {
          .left_block {
            border-radius: 24px 24px 0 0;
            border-bottom: 1px solid #F5F5F6;
          }

          .right_block {
            border-radius: 0 24px 0 0;
            border-bottom: 1px solid #D4D1D8;
          }
        }

        &:nth-child(2) {
          .left_block {
            border-radius: 0;
            border-bottom: 1px solid #F5F5F6;
          }

          .right_block {
            border-radius: 0 24px 0 0;
            border-bottom: 1px solid #D4D1D8;
          }
        }

        &:last-child {
          .left_block {
            border-radius: 0;
          }
        }
      }

      .left_block {
        width: 100%;
        max-width: 100%;
        box-sizing: border-box;
        padding: 20px;
        border-radius: 24px 24px 0 24px;
        background: #D4D1D8;

        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
          margin-bottom: 0;
          font-size: 18px;
          font-weight: 500;
          line-height: 23.4px;
          letter-spacing: 0.15px;
          text-align: left;
        }

        span {
          width: 89px;
          height: auto;
          padding: 8px 10px;
          border-radius: 8px;
          background: #FFFFFF;

          font-size: 13px;
          font-weight: 400;
          line-height: 16.9px;
          letter-spacing: 0.15px;
          text-align: center;
        }
      }

      .right_block {
        width: 100%;
        max-width: calc(100% - 60px);
        padding: 0 20px;
        border-radius: 0 24px 24px 0;

        .event {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: start;
          margin: 0;
        }

        .event_desc {
          max-width: 100%;
          margin-right: 20px;
          font-size: 18px;
          font-weight: 500;
          line-height: 23.4px;
          letter-spacing: 0.15px;
          text-align: left;
        }

        .event_guid {
          width: 100%;
          max-width: 100%;
          padding-right: 20px;
          margin-top: 3px;

          p {
            margin-top: 3px;
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: 400;
            line-height: 16.8px;
            letter-spacing: 0.28px;
            text-align: left;

            &:last-child {
              font-size: 14px;
              font-weight: 400;
              line-height: 19.6px;
              letter-spacing: 0.3px;
              text-align: left;
            }
          }
        }
      }

      .divider {
        display: flex;
        width: calc(100% - 60px);
        margin-bottom: 20px;
        border-bottom: 1px solid #D4D1D8;
        padding-right: 40px;
      }
    }

    .open_program {
      background: none;
      border: none;

      color: #428BF9;
      font-size: 15px;
      font-weight: 400;
      line-height: 19.5px;
      letter-spacing: 0.15px;
      text-align: center;

      cursor: pointer;
    }

    .table.table_mobile {
      .content {
        &:first-child {
          .left_block {
            border-radius: 24px 24px 0 0;
            border-bottom: none;
          }

          .right_block {
            border-bottom: none;
          }
        }

        &:last-child {
          .left_block {
            border-bottom: none;
          }
        }
      }

      .divider {
        width: 100%;
      }
    }
  }
}