.button {
  box-sizing: border-box;
  width: 100%;
  max-width: 400px;
  height: 68px;
  padding: 22.4px;
  border-radius: 12px;
  border: none;
  box-shadow: var(--box-shadow);
  background: none;

  // text
  color: #000000;
  font-size: 18px;
  font-weight: 400;
  line-height: 21.6px;
  letter-spacing: 0.18px;
  text-align: center;
  text-decoration: none;

  // other
  cursor: pointer;
  transition: var(--transition);

  &:hover {
    transition: var(--transition);
    text-decoration: none;
  }

  &.default {
    background: #FFDD2D;
    &:hover {
      background: #FCC521;
    }
  }

  &.variant2 {
    max-width: 154.2px;
    height: 44px;
    padding: 12px 16.11px;
    border-radius: 8px;
    background: #5C636B;

    // text
    color: #FFFFFF;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    &:hover {
      color: #000000CC;
      background: #DDDFE0;
    }
  }

  &.variant3 {
    border: 1px solid #A1A1B2;
    background: transparent;

    // text
    font-size: 18px;
    font-weight: 400;
    line-height: 21.6px;
    letter-spacing: 0.18px;
    text-align: center;

    &:hover {
      border: 1px solid #DDDFE0;
      background: #DDDFE0;
    }
  }

  &.variant4 {
    max-width: 154.2px;
    height: 44px;
    padding: 12px 16.11px;
    border-radius: 8px;
    background: #FFDD2D;

    // text
    color: #000000;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    text-align: center;

    &:hover {
      background: #FCC521;
    }
  }
}