.section {
  width: 100%;
  height: 100%;
  margin-top: -1px;
  background: #404040;

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 120px;
    padding: 120px 0;
    border-radius: 60px 60px 0 0;
    background: #2A2C35;
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  .title {
    color: #FFFFFF;
    font-size: 56px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.56px;
    text-align: center;
  }

  .cards {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    gap: 80px;

    .card {
      width: 256.66px;
      height: 221.99px;
      padding: 0 0.83px 1.39px 0.83px;
      gap: 19.1px;

      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .card_img {
      width: 140px;
      height: auto;
    }
    .card_desc {
      color: #FFFFFFE0;
      font-size: 22px;
      font-weight: 400;
      line-height: 30.8px;
      letter-spacing: 0.219px;
      text-align: center;
    }
  }

  .btn_accept {
    max-width: 288px;
  }

  .video {
    width: 100%;
    max-width: 100%;
    height: auto;
    flex-shrink: 0;
    padding: 0 20px;
    box-sizing: border-box;
    border-radius: 24px;

    iframe {
      border-radius: 24px;
    }
  }
}

@media (max-width: 1180px) {
  .section {
    .content {
      padding-right: 10px;
      padding-left: 10px;
    }
  }
}

@media (max-width: 850px) {
  .section {
    margin-top: -50px;
    background: transparent;
  }
}

@media (max-width: 768px) {
  .section {
    .cards {
      width: 100%;
      gap: 30px;
    }
  }
}

@media (max-width: 600px) {
  .section {

    .content {
      gap: 35px;
      padding: 35px 10px 0 10px;
      border-radius: 0;
    }

    .container {
      gap: 36px;
    }

    .container_mobile {
      width: calc(100% + 20px);
      padding-top: 56px;
      padding-bottom: 40px;
      border-radius: 20px 20px 0 0;
      background: #2f303b;
    }

    .title {
      font-size: 30px;
      font-weight: 700;
      line-height: 36px;
      letter-spacing: 0.28px;
      text-align: center;
    }

    .cards {
      gap: 16px;

      .card {
        width: 155.45px;
        height: 220px;

        display: flex;
        flex-direction: column;
        justify-content: start;
        align-items: center;
      }
      .card_img {
        width: 90px;
        height: auto;
      }
      .card_desc {
        font-size: 17px;
        font-weight: 400;
        line-height: 23.12px;
        letter-spacing: 0.17px;
        text-align: center;
      }
    }

    .btn_accept {
      max-width: 100%;
      height: 51px;
      padding: 13.5px;
      margin-top: 36px;
    }

    .video {
      padding: 0 10px;
      margin-bottom: 10px;

      iframe {
        max-height: 250.67px;
      }
    }
  }
}