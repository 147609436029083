.slider-custom {
  position: relative;

  .slick-prev {
    left: 0;
  }
  .slick-list {
    text-align: center;
  }
  .slick-slide {
  }
  .slick-next {
    right: 0;
  }
  .slick-dots {
    li {
      width: 15px;
      height: 15px;
      background-color: var(--light-grey2);
      border-radius: 50%;
      bottom: -10px;

      button {
        &:before {
          opacity: 1;
          color: var(--light-grey2);
        }
      }
    }
    li.slick-active {
      background-color: var(--gold-prim);
      button:before {
        opacity: 1;
        color: var(--gold-prim);
      }
    }
  }
}

.slider-swipe {
  position: absolute;
  top: 20px;
  left: 80px;
  animation: slideRightLeft 3s linear infinite;
  width: 35px;
  height: 25px;

  img {
    width: 100%;
    height: auto;
  }
}

@keyframes slideRightLeft {
  0% {
    left: 40px;
  }
  50% {
    left: 80px;
  }
  100% {
    left: 40px;
  }
}