.logo {
  display: flex;
  align-items: center;

  width: 178.98px;
  height: auto;

  img {
    width: 100%;
    height: 100%;
  }
}