.footer {
  margin-top: -1px;

  a {
    color: #0000008A;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
  }
  p {
    color: #00000066;
    font-size: 13px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0.15px;
    text-align: left;
  }

  .layout {
    min-height: 206.66px;
    background: #F3F4F6;
    box-shadow: none;
  }

  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 16px;
  }

  .divider {
    width: 100%;
    border-top: 1px solid #DDDFE0;
  }

  .info_block {
    width: 100%;
    display: flex;
    justify-content: space-between;

    &__right {
      display: flex;
      gap: 16px;
    }
  }
}

@media (max-width: 992px) {
  .footer {
    .layout {
      min-height: 230px;
      background: #F3F4F6;
      box-shadow: none;
    }

    .info_block {
      flex-wrap: wrap;
      gap: 10px;
    }
  }
}

@media (max-width: 700px) {
  .footer {
    .layout {
      min-height: 270px;
    }
  }
}

@media (max-width: 450px) {
  .footer {
    .layout {
      min-height: 320px;
    }

    nav > ul {
      flex-wrap: wrap;
      gap: 6px;
    }
  }
}