.layout {
  height: 100%;
  min-height: 80px;
  max-height: 80px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  padding: 10px 30px;
  gap: 10px;
  background: #11151DE5;
  box-shadow: -7px 0px 22px 0px #0000002B;

  color: var(--white);

  .container {
    justify-content: space-between;
  }
}