.section {
  width: 100%;
  max-width: 100%;
  //height: 100%;
  padding: 90px 0;
  margin-top: -1px;

  background: #404040 url('../../../../shared/assets/images/promo/Banner.webp') no-repeat center;
  background-position: 0 0;
  background-size: cover;


  .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;
    gap: 32px;
  }

  .block_container {
    width: 100%;
    max-width: 430px;
    background: #00000066;
    padding: 24px;
    border-radius: 24px;
  }

  .talk {
    width: 399.98px;
    height: 73.39px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  .title {
    color: #FFFFFF;
    font-size: 56px;
    font-weight: 700;
    line-height: 61px;
    letter-spacing: 0.56px;
    text-align: left;
  }

  .desc {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 24px;

    .btn_calendar {
      border: none;
      background: none;
      color: #428BF9;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
      text-align: left;
      text-decoration: none;
      cursor: pointer;
    }
  }
}

@media (max-width: 1300px) {
  .section {
    background-position: -100px 0;
  }
}

@media (max-width: 1180px) {
  .section {
    background-position: -250px 0;

    .container {
      padding: 0 30px;
    }
  }
}

@media (max-width: 992px) {
  .section {
    background-position: -400px 0;
  }
}

@media (max-width: 850px) {
  .section {
    background-position: -500px 0;
  }
}

@media (max-width: 655px) {
  .section {
    background-position: -600px 0;

    .container {
      align-items: center;
    }
  }
}

@media (max-width: 600px) {
  .section {
    padding-top: 28px;
    background-position: -400px 0;

    .block_container {
      width: 100%;
      max-width: 100%;
      background: #00000066;
      padding: 24px;
      border-radius: 24px;
    }

    .talk {
      width: 100%;
      height: auto;

      img {
        width: 100%;
        height: 100%;
      }
    }

    .title {
      font-size: 36px;
      font-weight: 700;
      line-height: 41px;
      letter-spacing: 1.08px;
      text-align: left;
    }

    .desc {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      .btn_accept {
        max-width: 100%;
        height: 51px;
        padding: 13.5px;
      }

      .btn_calendar {
        font-size: 17px;
        font-weight: 400;
        line-height: 23.12px;
        letter-spacing: 0.17px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 500px) {
  .section {
    background-position: -500px 0;
  }
}