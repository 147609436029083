.menu {
  ul {
    display: flex;
    gap: 24px;
  }
}

@media (max-width: 600px) {
  .menu {
    ul {
      a {
        color: #3375FF;
      }
    }
  }
}