.page {
  width: 100%;
  padding: 120px 0;
  margin-top: -1px;
  background: #F5F5F6;

  &__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .header {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &__title {
        color: #000000CC;
        font-size: 56px;
        font-weight: 700;
        line-height: 48px;
        letter-spacing: 0.56px;
        text-align: center;
      }
    }

    .content {
      width: 100%;
      display: flex;
      justify-content: center;
      margin-top: 64px;

      .accordion {
        width: 100%;
      }
    }

    &:first-child {
      margin-bottom: 120px;
    }
  }

  .tg {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 48px;
    margin-top: 64px;

    img {
      width: 196px;
      height: auto;
    }

    .tg_desc {
      max-width: 306px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 24px;

      p {
        color: #000000CC;
        font-size: 17px;
        font-weight: 400;
        line-height: 23px;
        letter-spacing: 0.15px;
        text-align: center;
      }
    }
  }
}

@media (max-width: 1440px) {
  .page {
    &__container {
      padding: 0 30px;

      .content {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}

@media (max-width: 1180px) {
  .page {
    .tg {
      padding: 0 10px;
    }
  }
}

@media (max-width: 992px) {
  .page {
    &__container {
      .header {
        &__title {
          margin-bottom: 0;
          font-size: 4vw;
          line-height: 6vw;
        }
      }
    }
  }
}

@media (max-width: 655px) {
  .page {
    padding-top: 32px;

    &__container {
      .header {
        &__title {
          font-size: 24px;
          line-height: 31px;
          letter-spacing: -0.02em;
        }
      }
      .content {
        margin-top: 24px;
      }
    }
  }
}

@media (max-width: 600px) {
  .page {
    z-index: 2;
    margin-top: -20px;
    padding: 56px 0;
    border-radius: 20px 20px 0 0;

    &__container {
      .content {
        margin-top: 16px;
      }

      &:first-child {
        margin-bottom: 64px;
      }
    }

    .tg {
      width: 100%;
      flex-wrap: wrap;
      margin-top: 36px;

      img {
        width: 196px;
        height: auto;
      }

      .tg_desc {
        max-width: 100%;

        p {
          font-size: 17px;
          font-weight: 400;
          line-height: 23px;
          letter-spacing: 0.15px;
          text-align: center;
        }

        .btn_accept {
          max-width: 100%;
          height: 44px;
          padding: 10px;
          border-radius: 8px;
        }
      }
    }
  }
}