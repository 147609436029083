.section {
  width: 100%;
  height: 100%;
  margin-top: -1px;
  background: #F5F5F6;


  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 10px 120px 10px;
    border-radius: 60px 60px 0 0;
    background: #F5F5F6;
  }

  .container {
    width: 100%;
    max-width: 1120px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 64px;
  }

  .title {
    color: #000000;
    font-size: 56px;
    font-weight: 700;
    line-height: 48px;
    letter-spacing: 0.56px;
    text-align: center;
  }

  .cards {
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-wrap: wrap;
    gap: 32px;

    .card {
      width: 198px;
      height: 503.88px;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    .card_img {
      width: 100%;
      height: auto;
      margin-bottom: 28px;
    }
    .card_name {
      max-width: 165.83px;
      margin-bottom: 3px;
      color: #000000CC;
      font-size: 24px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
      text-align: center;
    }
    .card_desc {
      max-width: 165.83px;
      margin-bottom: 11px;
      color: #000000CC;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.44px;
      letter-spacing: 0.159px;
      text-align: center;
    }
    .card_text {
      max-width: 190px;
      color: #00000066;
      font-size: 16px;
      font-weight: 400;
      line-height: 21.44px;
      letter-spacing: 0.1599999964237213px;
      text-align: center;
    }
  }

  button {
    max-width: 288px;
  }

  .video {
    width: 100%;
    max-width: 100%;
    height: auto;
    flex-shrink: 0;
    padding: 0 20px;
    box-sizing: border-box;
  }
}

@media (max-width: 1137px) {
  .section {
    .container {
      max-width: 900px;
    }
  }
}

@media (max-width: 908px) {
  .section {
    .container {
      max-width: 670px;
    }
  }
}

@media (max-width: 677px) {
  .section {
    .container {
      max-width: 430px;
    }
  }
}

@media (max-width: 600px) {
  .section {
    .content {
      padding-right: 10px;
      padding-left: 10px;
      padding-bottom: 60px;
    }

    .cards {
      justify-content: center;
    }
  }
}