.accordion {
  display: flex;
  flex-direction: column;

  &__item {
    padding: 28px 0 29px 0;
    gap: 8px;
    border-bottom: 1px solid #0010241F;
    cursor: pointer;

    .header {
      display: flex;
      justify-content: space-between;
      align-items: center;

      &__title {
        color: #000000CC;
        font-size: 28px;
        font-weight: 500;
        line-height: 33.6px;
        letter-spacing: 0.15px;
        text-align: left;
      }
      &__btn {
        font-size: 20px;
        padding: 5px;
        border: none;
        background-color: transparent;
        color: #8D8D9B;
        cursor: pointer;

        transition: transform 0.3s ease;
      }
      &__btn--down {
        transform: rotate(-180deg);
      }
    }

    &:first-child {
      padding-top: 0;
    }
  }
  &__content {
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: -0.1px;
    color: var(--light-blue);

    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease;

    &--open {
      display: block;
      padding-top: 8px;

      max-height: 200px;
    }
    &--closed {
      display: none;
    }
  }

  .item {
    display: flex;
    flex-direction: column;

    color: #0000008A;
    font-size: 20px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.2px;
    text-align: left;

    a {
      color: #3C7BFF;
      font-size: 20px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0.2px;
      text-align: left;
      text-decoration: underline;
    }
  }
}

@media (max-width: 600px) {
  .accordion {
    &__item {
      padding: 16px 0 17px 0;

      .header {
        &__title {
          color: #000000CC;

          font-size: 18px;
          font-weight: 500;
          line-height: 25.2px;
          letter-spacing: 0.15px;
          text-align: left;
        }
      }
    }

    .item {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: 0.2px;
      text-align: left;

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 20px;
        letter-spacing: 0.2px;
        text-align: left;
      }
    }
  }
}