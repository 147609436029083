.card {
  width: 198px;
  height: 503.88px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;

  &__img {
    width: 100%;
    height: auto;
    margin-bottom: 16px;

    img {
      width: 100%;
      height: auto;
      margin-bottom: 28px;
      border-radius: 12px 12px 0 0;
    }
  }
  &__title {
    color: #000000CC;
    font-size: 20px;
    font-weight: 500;
    line-height: 28px;
    letter-spacing: 0.15px;
    text-align: center;
  }
  &__desc {
    color: #000000CC;
    font-size: 15px;
    font-weight: 400;
    line-height: 21.44px;
    letter-spacing: 0.159px;
    text-align: center;

    p {
      &:last-child {
        color: #00000066;
        font-size: 15px;
        font-weight: 400;
        line-height: 21.44px;
        letter-spacing: 0.159px;
        text-align: center;
      }
    }
  }
}
.cards {
  width: 100%;
  display: flex;
  gap: 15px;

  .card {
    @extend .card;
  }
}
.cards_slider {
  max-width: 400px;

  .card {
    @extend .card;

    max-width: 343px;
    min-height: 376px;
    text-align: start;

    &__img {
      margin-bottom: 26px;
    }
    &__title {
      margin-bottom: 10px;

      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      letter-spacing: 0.15px;
      text-align: center;
    }
    &__desc {
      p {
        font-size: 15px;
        font-weight: 400;
        line-height: 21.44px;
        letter-spacing: 0.15px;
        text-align: center;

        &:last-child {
          margin-top: 30px;

          font-size: 15px;
          font-weight: 400;
          line-height: 21.44px;
          letter-spacing: 0.15px;
          text-align: center;
        }
      }
    }
  }
}

@media (max-width: 1200px) {
  .cards {
    .card {
      &__img {
        width: 30px;
        height: 28px;
        margin-bottom: 16px;
      }
      &__title {
        font-size: 1.6vw;
        line-height: 1.6vw;
      }
      &__desc {
        font-size: 1.4vw;
        line-height: 1.4vw;
      }
    }
  }
}
@media (max-width: 600px) {
  .cards {
    .card {
      &__img {
        width: 30px;
        height: 28px;
        margin-bottom: 16px;
      }
      &__title {
        font-size: 20px;
        font-weight: 500;
        line-height: 28px;
        letter-spacing: 0.15px;
        text-align: center;

        p:last-child {

        }
      }
      &__desc {
        font-size: 1.4vw;
        line-height: 1.4vw;
      }
    }
  }
}
@media (max-width: 500px) {
  .cards_slider {
    max-width: 360px;
    .card {
      max-width: 300px;
    }
  }
}
@media (max-width: 400px) {
  .cards_slider {
    max-width: 300px;

    .card {
      max-width: 250px;
    }
  }
}