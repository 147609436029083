.header {
  z-index: 3;
  width: 100%;
  position: fixed;

  &__left_icon {
    width: 25px;
    height: 25px;
    position: absolute;
    top: 18px;
    left: 25px;
  }
  &__right_block {
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
}

@media (max-width: 767.9px) {
  .header {
    .layout {
      border-bottom: none;

      div {
        justify-content: space-between;
        gap: 20px;
      }
    }

    .btn_accept {
      width: 130px;
      height: 32px;
      padding: 5.5px 11.74px 6.5px 11.73px;
      border-radius: 8px;
    }

    .btn_burger {
      width: 30px;
      height: 30px;
      cursor: pointer;

      img {
        width: 100%;
        height: auto;
      }
    }
  }
}