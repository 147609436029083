@font-face {
    font-family: 'Tinkoff Sans';
    src: url('TinkoffSans-Bold.eot');
    src: local('Tinkoff Sans Bold'), local('TinkoffSans-Bold'),
        url('TinkoffSans-Bold.eot?#iefix') format('embedded-opentype'),
        url('TinkoffSans-Bold.woff2') format('woff2'),
        url('TinkoffSans-Bold.woff') format('woff'),
        url('TinkoffSans-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tinkoff Sans';
    src: url('TinkoffSans-Medium.eot');
    src: local('Tinkoff Sans Medium'), local('TinkoffSans-Medium'),
        url('TinkoffSans-Medium.eot?#iefix') format('embedded-opentype'),
        url('TinkoffSans-Medium.woff2') format('woff2'),
        url('TinkoffSans-Medium.woff') format('woff'),
        url('TinkoffSans-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Tinkoff Sans';
    src: url('TinkoffSans-Regular.eot');
    src: local('Tinkoff Sans Regular'), local('TinkoffSans-Regular'),
        url('TinkoffSans-Regular.eot?#iefix') format('embedded-opentype'),
        url('TinkoffSans-Regular.woff2') format('woff2'),
        url('TinkoffSans-Regular.woff') format('woff'),
        url('TinkoffSans-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

