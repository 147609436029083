.menu {
  ul {
    display: flex;
    flex-direction: row;

    li {
      margin-right: 24px;

      &:last-child {
        margin-right: 36px;
      }
    }
  }

  a {
    color: #FFFFFFB8;
    font-size: 15px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.15px;
    text-align: left;
  }
}